import React from 'react'

const Form = function form ({info_bb,info_bb_c,info_bb_d}){
    let values_1 = ''
    if(info_bb_c.values_1 == 'si'){
        values_1 = 'checked'
    }
    let values_2 = ''
    if(info_bb_c.values_2 == 'si'){
        values_2 = 'checked'
    }
    let values_3 = ''
    if(info_bb_c.values_3 == 'si'){
        values_3 = 'checked'
    }
    let values_4 = ''
    if(info_bb_c.values_4 == 'si'){
        values_4 = 'checked'
    }
    let values_5 = ''
    if(info_bb_c.values_5 == 'si'){
        values_5 = 'checked'
    }
    let values_6 = ''
    if(info_bb_c.values_6 == 'si'){
        values_6 = 'checked'
    }
    let values_7 = ''
    if(info_bb_c.values_7 == 'si'){
        values_7 = 'checked'
    }
    let values_8 = ''
    if(info_bb_c.values_8 == 'si'){
        values_8 = 'checked'
    }
    let qfive_1 = ''
    if(info_bb_c.qfive_1 == 'si'){
        qfive_1 = 'checked'
    }
    let qfive_2 = ''
    if(info_bb_c.valueqfive_2s_1 == 'si'){
        qfive_2 = 'checked'
    }
    let qfive_3 = ''
    if(info_bb_c.qfive_3 == 'si'){
        qfive_3 = 'checked'
    }
    let qfive_4 = ''
    if(info_bb_c.qfive_4 == 'si'){
        qfive_4 = 'checked'
    }
    let qsix_1 = ''
    if(info_bb_c.qsix_1 == 'si'){
        qsix_1 = 'checked'
    }
    let qsix_2 = ''
    if(info_bb_c.qsix_2 == 'si'){
        qsix_2 = 'checked'
    }
    let qsix_3 = ''
    if(info_bb_c.qsix_3 == 'si'){
        qsix_3 = 'checked'
    }
    let qsix_4 = ''
    if(info_bb_c.qsix_4 == 'si'){
        qsix_4 = 'checked'
    }

    let qnine_1 = ''
    if(info_bb_d.qnine_1 == 'si'){
        qnine_1 = 'checked'
    }
    let qnine_2 = ''
    if(info_bb_d.qnine_2 == 'si'){
        qnine_2 = 'checked'
    }
    let qnine_3 = ''
    if(info_bb_d.qnine_3 == 'si'){
        qnine_3 = 'checked'
    }
    let qnine_4 = ''
    if(info_bb_d.qnine_4 == 'si'){
        qnine_4 = 'checked'
    }
    let qnine_5 = ''
    if(info_bb_d.qnine_5 == 'si'){
        qnine_5 = 'checked'
    }
    let qnine_6 = ''
    if(info_bb_d.qnine_6 == 'si'){
        qnine_6 = 'checked'
    }
    
    let q12_one = ''
    if(info_bb_d.q12_one == 'si'){
        q12_one = 'checked'
    }
    let q12_two = ''
    if(info_bb_d.q12_two == 'si'){
        q12_two = 'checked'
    }
    let lettering = ''
    if(info_bb_d.lettering == 'si'){
        lettering = 'checked'
    }
    let emblem = ''
    if(info_bb_d.emblem == 'si'){
        emblem = 'checked'
    }
    let abstract = ''
    if(info_bb_d.abstract == 'si'){
        abstract = 'checked'
    }
    let minimalist = ''
    if(info_bb_d.minimalist == 'si'){
        minimalist = 'checked'
    }
    return (
        <section className='sectionForm sectionFormPrint'>
            <div className='row rowOne'>
                <form id="formBriefBrand" action="">
                    <div className='col s12'>
                        <div className='element'>
                        <div className='row'>
                                <div className='col s12'>
                                    <h3>
                                        Nombre o Razón social*
                                    </h3>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí' 
                                        name='name'
                                        value={info_bb.name}
                                        readOnly
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>1.</span> ¿Su empresa a qué se dedica?
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_one'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_one}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>2.</span> ¿Quién es tu cliente objetivo o cliente potencial? (Menciona edad, estrato y cualquier otra característica que lo defina)
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_two'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_two}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>3.</span> ¿Cuál es tu competencia directa?
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_three'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_three}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>4.</span> ¿Qué valores promueve su empresa?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_1'
                                                value={info_bb_c.values_1}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_1}
                                            />
                                            <span>Respeto</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_2'
                                                value={info_bb_c.values_2}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_2}
                                            />
                                            <span>Solidaridad</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_3'
                                                value={info_bb_c.values_3}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_3}
                                            />
                                            <span>Amor</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_4'
                                                value={info_bb_c.values_4}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_4}
                                            />
                                            <span>Honestidad</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_5'
                                                value={info_bb_c.values_5}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_5}
                                            />
                                            <span>Empatía</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_6'
                                                value={info_bb_c.values_6}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_6}
                                            />
                                            <span>Gratitud</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_7'
                                                value={info_bb_c.values_7}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_7}
                                            />
                                            <span>Responsabilidad</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='values_8'
                                                value={info_bb_c.values_8}
                                                readOnly
                                                disabled="disabled"
                                                checked={values_8}
                                            />
                                            <span>Voluntad</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='values_other'
                                        value={info_bb_c.values_other}
                                        readOnly
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>5.</span> ¿Cuál crees que es tu principal diferenciador?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qfive_1'
                                                value={info_bb_c.qfive_1}
                                                readOnly
                                                disabled="disabled"
                                                checked={qfive_1}
                                            />
                                            <span>Servicio</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qfive_2'
                                                value={info_bb_c.qfive_2}
                                                readOnly
                                                disabled="disabled"
                                                checked={qfive_2}
                                            />
                                            <span>Personal</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qfive_3'
                                                value={info_bb_c.qfive_3}
                                                readOnly
                                                disabled="disabled"
                                                checked={qfive_3}
                                            />
                                            <span>Calidad</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qfive_4'
                                                value={info_bb_c.qfive_4}
                                                readOnly
                                                disabled="disabled"
                                                checked={qfive_4}
                                            />
                                            <span>Precio</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='qfive_other'
                                        value={info_bb_c.qfive_other}
                                        readOnly
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>6.</span> ¿Cómo te gustaría que los demás perciban tu marca?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qsix_1'
                                                value={info_bb_c.qsix_1}
                                                readOnly
                                                disabled="disabled"
                                                checked={qsix_1}
                                            />
                                            <span>Elegante</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qsix_2'
                                                value={info_bb_c.qsix_2}
                                                readOnly
                                                disabled="disabled"
                                                checked={qsix_2}
                                            />
                                            <span>Cercana</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qsix_3'
                                                value={info_bb_c.qsix_3}
                                                readOnly
                                                disabled="disabled"
                                                checked={qsix_3}
                                            />
                                            <span>Premium</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qsix_4'
                                                value={info_bb_c.qsix_4}
                                                readOnly
                                                disabled="disabled"
                                                checked={qsix_4}
                                            />
                                            <span>Exclusiva</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='qsix_other'
                                        value={info_bb_c.qsix_other}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='col s12'>
                        <div className='element'>
                        <div className='row'>
                                <div className='col s12'>
                                    <h5>
                                        <span>7.</span> ¿Tu negocio tiene slogan?
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_seven'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_seven}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>8.</span> Menciona alguna marca referente con la que te identifiques (Puede ser de tu sector o no)
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_eight'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_eight}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>9.</span> Menciona algunos de los colores que deseas sean incluidos en tu marca
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qnine_1'
                                                value={info_bb_d.qnine_1}
                                                readOnly
                                                disabled="disabled"
                                                checked={qnine_1}
                                            />
                                            <span>Azul</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qnine_2'
                                                value={info_bb_d.qnine_2}
                                                readOnly
                                                disabled="disabled"
                                                checked={qnine_2}
                                            />
                                            <span>Amarillo</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qnine_3'
                                                value={info_bb_d.qnine_3}
                                                readOnly
                                                disabled="disabled"
                                                checked={qnine_3}
                                            />
                                            <span>Verde</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qnine_4'
                                                value={info_bb_d.qnine_4}
                                                readOnly
                                                disabled="disabled"
                                                checked={qnine_4}
                                            />
                                            <span>Rojo</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qnine_5'
                                                value={info_bb_d.qnine_5}
                                                readOnly
                                                disabled="disabled"
                                                checked={qnine_5}
                                            />
                                            <span>Naranja</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='qnine_6'
                                                value={info_bb_d.qnine_6}
                                                readOnly
                                                disabled="disabled"
                                                checked={qnine_6}
                                            />
                                            <span>Negro</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='qnine_other'
                                        value={info_bb_d.qnine_other}
                                        readOnly
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>10.</span> ¿Qué elementos gráficos te gustaría incluir en tu marca? (Iconos representativos del sector)
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_ten'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_ten}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>11.</span> ¿Qué elementos gráficos no puede contener la marca? (Cuéntanos si existe algún icono que no deseas que sea incluido)
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_eleven'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_eleven}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                <div className='col s12'>
                                    <h5>
                                        <span>12.</span> ¿Por cuál tipografía sientes más afinidad?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p className='p_img'>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q12_one'
                                                value={info_bb_d.q12_one}
                                                readOnly
                                                disabled="disabled"
                                                checked={q12_one}
                                            />
                                            <span>
                                                <img src='../../img/brand/sanserif.svg'/>
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p className='p_img'>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q12_two'
                                                value={info_bb_d.q12_two}
                                                readOnly
                                                disabled="disabled"
                                                checked={q12_two}
                                            />
                                            <span>
                                                <img src='../../img/brand/caligrafica.svg'/>
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>13.</span> ¿Por cuál estilo de marca sientes más afinidad?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p className='p_img_2 p_img_2_initial'>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='lettering'
                                                value={info_bb_d.lettering}
                                                readOnly
                                                disabled="disabled"
                                                checked={lettering}
                                            />
                                            <span>
                                                <img src='../../img/brand/style_1.svg'/>
                                                Lettering
                                            </span>
                                        </label>
                                    </p>
                                    <p className='p_img_2'>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='emblem'
                                                value={info_bb_d.emblem}
                                                readOnly
                                                disabled="disabled"
                                                checked={emblem}
                                            />
                                            <span>
                                                <img className='img_2' src='../../img/brand/style_2.svg'/>
                                                Emblema
                                            </span>
                                        </label>
                                    </p>
                                    <p className='p_img_2'>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='abstract'
                                                value={info_bb_d.abstract}
                                                readOnly
                                                disabled="disabled"
                                                checked={abstract}
                                            />
                                            <span>
                                                <img className='img_3' src='../../img/brand/style_3.svg'/>
                                                Abstracto
                                            </span>
                                        </label>
                                    </p>
                                    <p className='p_img_2 p_img_2_finally'>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='minimalist'
                                                value={info_bb_d.minimalist}
                                                readOnly
                                                disabled="disabled"
                                                checked={minimalist}
                                            />
                                            <span>
                                                <img className='img_4' src='../../img/brand/style_4.svg'/>
                                                Minimalista
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>14.</span> Si tienes comentarios adicionales déjanos saber aquí
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_14'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bb.q_14}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </section>
    )
}

export default Form