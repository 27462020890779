import React from 'react'

let vector = [0]

const Form = function form ({info_bw,info_bw_q,info_bw_d}){
    let q3_name = ''
    if(info_bw_q.q3_name == 'si'){
        q3_name = 'checked'
    }
    let q3_phone = ''
    if(info_bw_q.q3_phone == 'si'){
        q3_phone = 'checked'
    }
    let q3_ubication = ''
    if(info_bw_q.q3_ubication == 'si'){
        q3_ubication = 'checked'
    }
    let q3_company = ''
    if(info_bw_q.q3_company == 'si'){
        q3_company = 'checked'
    }
    let q3_mail = ''
    if(info_bw_q.q3_mail == 'si'){
        q3_mail = 'checked'
    }
    let q3_gener = ''
    if(info_bw_q.q3_gener == 'si'){
        q3_gener = 'checked'
    }
    let blog_yes = ''
    if(info_bw_q.blog == 'si'){
        blog_yes = 'checked'
    }
    let blog_no = ''
    if(info_bw_q.blog == 'no'){
        blog_no = 'checked'
    }
    return (
        <section className='sectionForm sectionFormPrint'>
            <form id="formBriefWeb" action="">
                <div className='row rowOne'>
                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                    <div className='col s12'>
                                        <h3>
                                            Nombre o Razón social*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí' 
                                            name='name'
                                            value={info_bw.name}
                                            readOnly
                                        />
                                    </div>
                                    <div className='col s12'>
                                        <h3>
                                            Dirección*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='address'
                                            value={info_bw.address}
                                            readOnly
                                        />
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            Whatsapp principal*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='whatsapp_first'
                                            value={info_bw.whatsapp_first}
                                            readOnly
                                        />
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            Número celular secundario
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='phone_second'
                                            value={info_bw.phone_second}
                                            readOnly
                                        />
                                    </div>
                                    <div className='col s12'>
                                        <h3>
                                            Enlaces de redes sociales*
                                        </h3>
                                        <h4>
                                            (Facebook, Instagram, Twitter, LinkedIn, otras ¿Cuál?)
                                        </h4>
                                        <div 
                                            className="materialize-textarea"
                                            placeholder='Escribe texto aquí'
                                            name='link_nets'
                                            readOnly
                                        >
                                            <h4>
                                                {info_bw.link_nets}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            E-mail principal*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='email_first'
                                            value={info_bw.email_first}
                                            readOnly
                                        />
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            E-mail alternativa
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='email_second'
                                            value={info_bw.email_second}
                                            readOnly
                                        />
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                <div className='col s12'>
                                    <h5>
                                        <span>1.</span> Sobre tu negocio (Escribe una breve descripción, te sugerimos escribir: Historia de la empresa, cómo surgió, cuáles han sido sus progresos, logros significativos, porque debo escogerlos como  proveedor etc.)
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_one'
                                        readOnly
                                    >
                                        <h4>{info_bw_q.q_one}</h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>2.</span> Cuéntanos cuáles son los servicios / productos que tu empresa ofrece, si son varios...
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_two'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bw_q.q_two}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>3.</span> Selecciona los datos que consideras indispensables en el formulario de tu sitio web
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q3_name'
                                                value={info_bw_q.q3_name}
                                                readOnly
                                                disabled="disabled"
                                                checked={q3_name}
                                            />
                                            <span>Nombre</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q3_phone'
                                                value={info_bw_q.q3_phone}
                                                readOnly
                                                disabled="disabled"
                                                checked={q3_phone}
                                            />
                                            <span>Teléfono</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q3_ubication'
                                                value={info_bw_q.q3_ubication}
                                                readOnly
                                                disabled="disabled"
                                                checked={q3_ubication}
                                            />
                                            <span>Ubicación</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q3_company'
                                                value={info_bw_q.q3_company}
                                                readOnly
                                                disabled="disabled"
                                                checked={q3_company}
                                            />
                                            <span>Nombre de empresa</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q3_mail'
                                                value={info_bw_q.q3_mail}
                                                readOnly
                                                disabled="disabled"
                                                checked={q3_mail}
                                            />
                                            <span>E-mail</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='q3_gener'
                                                value={info_bw_q.q3_gener}
                                                readOnly
                                                disabled="disabled"
                                                checked={q3_gener}
                                            />
                                            <span>Género</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='q3_other'
                                        value={info_bw_q.q3_other}
                                        readOnly
                                    />
                                </div>
                                <div className='col s12'>
                                    <h3 className='h3'>
                                        INFORMACIÓN SOBRE GUSTOS Y PREFERENCIAS
                                    </h3>
                                    <h5>
                                        <span>-</span> Nombre las direcciones de correo requeridas para su negocio (<span className='span_2'>Info@example.com - admon@example.com</span>)
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='emails'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bw_q.emails}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>-</span> Páginas de referencia:
                                    </h5>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='pages_reference'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bw_q.pages_reference}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>-</span> ¿Requiere blog?:
                                    </h5>
                                </div>
                                <div className='col s6 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='blog_yes'
                                                value={info_bw_q.blog_yes}
                                                readOnly
                                                disabled="disabled"
                                                checked={blog_yes}
                                            />
                                            <span>Si</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s6 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox"
                                                name='blog_no'
                                                value={info_bw_q.blog_no}
                                                readOnly
                                                disabled="disabled"
                                                checked={blog_no}
                                            />
                                            <span>No</span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                <div className='col s12'>
                                    <h3>
                                        Por favor compártenos otros datos necesarios (si existen):
                                    </h3>
                                    <h4>
                                        Usuario y claves de dominio
                                    </h4>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='user_password_domain'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bw_d.user_password_domain}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h4>
                                        Acceso a las cuentas de correo actuales
                                    </h4>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='access_mail'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bw_d.access_mail}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h4>
                                        Usuario y contraseña de Instagram para la integración con el sitio
                                    </h4>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='user_password_instagram'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bw_d.user_password_instagram}
                                        </h4>
                                    </div>
                                </div>
                                <div className='col s12'>
                                    <h4>
                                        Para analytics: Código de seguimiento
                                    </h4>
                                    <div   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='analitycs_code_follow'
                                        readOnly
                                    >
                                        <h4>
                                            {info_bw_d.analitycs_code_follow}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
        </section>
    )
}

export default Form
