import React,{useRef} from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import Header from "../components/header";
import Footer from "../components/footer";

import Form from "../components/login/form";

export default class Login extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'login',
            banner: [],
            error: null,
            formLogin:{
                user:'',
                password:'',
                token:''
            }
        }

        this.formSubmit = this.formSubmit.bind(this)
        this.handleChangeForm = this.handleChangeForm.bind(this)
    }

    handleChangeForm(e){
        this.setState({
            formLogin:{
                ...this.state.formLogin,
                [e.target.name]:e.target.value
            }
        })
    }
    
    formSubmit = async (e) => {
        e.preventDefault()
        if(
            this.state.formLogin.user == '' ||
            this.state.formLogin.password == ''
        ){
            swal({
                text: "Debes digitar todos los datos requeridos *",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });
        
        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.formLogin)
            }
            let res = await fetch(`api/login`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                })
                swal.close();
                document.getElementById("formLogin").reset();
                $("#formLogin")[0].reset();
                location.href = 'list';
            }else if(data == 2){
                swal.close();
                swal({
                    text: "Datos incorrectos, intente nuevamente",
                    icon: imageURL,
                    button: 'OK',
                    closeOnClickOutside: false,
                });
            }else{
                console.log(data);
                alert('ocurrio un error');
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    async componentDidMount(){
        //info
        try{
            let res = await fetch('api/list')
            let data = await res.json()
            this.setState({
                banner:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        
    }

    render() {
        return (
            <main>
                <Header/>
                <Form
                    form={this.state.formLogin}
                    onChange={this.handleChangeForm}
                    onSubmit={this.formSubmit}
                />
                <Footer/>
            </main>
        );
    }

}

if (document.getElementById('content_login')) {
    ReactDOM.render(<Login />, document.getElementById('content_login'))
}
