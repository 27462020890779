import React from 'react'

var vector = [1];
var vector_pages = [];

const Table = function table ({list,filters,onChange,onKeyPress,
    prevHandler,nextHandler,currentPage,changeHandler,pages}){

    const items = list.map((l)=>{
        var created_at = l.created_at;
        let date = created_at.split('T');
        return(
            <tr key={l.id}>
                <td className="border-first">
                    {l.id}
                </td>
                <td>
                    {date[0]}
                </td>
                <td>
                    {l.name}
                </td>
                <td>
                    <a href={'../see/brand/'+l.id}>
                        <i className="material-icons">
                            remove_red_eye
                        </i>
                    </a>
                </td>
                <td>
                    <a href="">
                        <i className="material-icons">
                            mode_edit
                        </i>
                    </a>
                </td>
                <td className="border-old">
                    <a className="deleteContract">
                        <i className="material-icons">
                            delete_forever
                        </i>
                    </a>
                </td>
            </tr>
        )
    })
    let pagination = ""
    let page = ""
    if(parseInt(pages) >= 1){

        vector_pages = [];
        for (let i = 0; i<pages; i++){
            vector_pages[i] = i
        }
        pagination = vector.map((v)=>{
            return (
                <ul className="pagination" key={1}>
                    <li className="waves-effect">
                        <a onClick={prevHandler}>
                            <i className="material-icons">chevron_left</i>
                        </a>
                    </li>
                    {vector_pages.map((v)=>{
                        return (
                            <li className={currentPage == v?"active":""} onClick={() => changeHandler(v)} key={v}>
                                <a>{v+1}</a>
                            </li>
                        )
                    })}

                    <li className="waves-effect">
                        <a onClick={nextHandler}>
                            <i className="material-icons">chevron_right</i>
                        </a>
                    </li>
                </ul>
            )
        })
    }else{
        vector_pages = [];
        vector_pages[0] = 0
        pagination = vector.map((v)=>{
            return (
                <ul className="pagination" key={1}>
                    <li className="waves-effect">
                        <a onClick={prevHandler}>
                            <i className="material-icons">chevron_left</i>
                        </a>
                    </li>
                    {vector_pages.map((v)=>{
                        return (
                            <li className={currentPage == v?"active":""} onClick={() => changeHandler(v)} key={v}>
                                <a>{v+1}</a>
                            </li>
                        )
                    })}

                    <li className="waves-effect">
                        <a onClick={nextHandler}>
                            <i className="material-icons">chevron_right</i>
                        </a>
                    </li>
                </ul>
            )
        })
    }

    return (
<section className="sectionIndexView">
    <div className="row rowOne">
        <div className="col s12">
            <div className="row">
                <div className="col s12 m3 l3">
                    <h1>
                        Brief Marca
                    </h1>
                </div>
                <div className="col s12 m3 l3">
                    <a className="waves-effect waves-light btn" href={'/brand'}>
                        <i className="material-icons right">add_box</i>
                        Agregar Brief Marca
                    </a>
                </div>
            </div>
        </div>
        <div className="col s12">
            <table className="highlight">
                <thead>
                    <tr>
                        <th className="border-tr">ID</th>
                        <th>Fecha</th>
                        <th>Razon Social</th>
                        <th></th>
                        <th></th>
                        <th className="border-th"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="search-table-input">
                        <th>
                            <input style={{width:'50px'}}
                                id="id" 
                                type="text"
                                name="id"
                                value={filters.id}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                        </th>
                        <th>
                            <input 
                                id="date" 
                                type="date" 
                                timezone="us"
                                name="date"
                                value={filters.date}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                        </th>
                        <th>
                            <input 
                                id="name" 
                                type="text"
                                name="name"
                                value={filters.name}
                                onChange={onChange}
                                onKeyPress={onKeyPress}
                            />
                        </th>
                    </tr>
                    {items}
                </tbody>
            </table>
            {pagination}
        </div>
    </div>
</section>
    )
}

export default Table
