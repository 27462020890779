import React from 'react'

const Footer = function footer (){
    return (
        <div>
            <a href="https://api.whatsapp.com/send?phone=573165206993&text=Hola%21%20Quisiera%20m%C3%A1s%20informacion" target="_blank" className="floatW">
                <img src="/img/whatsapp.svg" alt="📞"/>
            </a>
            <section className="footer">
                <span className='version'>Version 1.1.0</span>
                <div className="row rowOne">
                    <div className="col s12 m6 l6">
                        <div className="row">
                            <div className="col s12 m4 l4">
                                <h1>
                                    Contáctanos
                                </h1>
                                <a href="mailto:gerencia@gutand.com">
                                    <h2>
                                        gerencia@gutand.com
                                    </h2>
                                </a>
                            </div>
                            <div className="col s12 m4 l4">
                                <h1 className="ocult">
                                    &ensp;
                                </h1>
                                <a href="tel:+573165206993">
                                    <h2>
                                        +57 316 5206993
                                    </h2>
                                </a>
                            </div>
                            <div className="col s12 m4 l4">
                                <h1 className="ocult">
                                    &ensp;
                                </h1>
                                <a href="https://gutand.com/" target="_blank">
                                    <h2>
                                        www.gutand.com
                                    </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m6 l6">
                        <div className="row">
                            <div className="col s12 m4 l4">
                                <h1>
                                    Síguenos
                                </h1>
                                <a href="https://www.instagram.com/gutand_col/" target="_blank">
                                    <h2 className="h2">
                                        <img src="/img/footer/instagram.svg"/>
                                        <span>gutand_col</span>
                                    </h2>
                                </a>
                            </div>
                            <div className="col s12 m4 l4">
                                <h1 className="ocult">
                                    &ensp;
                                </h1>
                                <a href="https://www.facebook.com/gutand.col/" target="_blank">
                                    <h2 className="h2">
                                        <img src="/img/footer/facebook.svg"/>
                                        <span>gutand.col</span>
                                    </h2>
                                </a>
                            </div>
                            <div className="col s12 m4 l4">
                                <h1 className="ocult">
                                    &ensp;
                                </h1>
                                <a href="https://www.linkedin.com/company/gutand" target="_blank">
                                    <h2 className="h2">
                                        <img src="/img/footer/linkeding.svg"/>
                                        <span>gutand</span>
                                    </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
