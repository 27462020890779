import React,{useRef} from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import Header from "../components/header";
import Footer from "../components/footer";
import List from "../components/index/list";

export default class Index extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'index',
            list: [],
            error: null,
        }
    }
    
    async componentDidMount(){
        //info
        try{
            let res = await fetch('api/list')
            let data = await res.json()
            this.setState({
                list:data
            })
            /*fetch("api/list")
            .then(res => res.json())
            .then(
                (data) => {
                    this.setState({
                        list:data
                    })
                    console.log(this.state.list)
                },
                (error) => {
                    this.setState({
                        error
                    })
                }
            )*/
        }catch (error) {
            this.setState({
                error
            })
        }
        
    }

    render() {
        return (
            <main>
                <Header/>
                <List
                    list={this.state.list}
                />
                <Footer/>
            </main>
        );
    }

}

if (document.getElementById('content_index')) {
    ReactDOM.render(<Index />, document.getElementById('content_index'))
}
