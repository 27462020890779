import React from 'react'

const Form = function form ({form,onChange,onSubmit}){
    return (
        <section className="sectionFormLogin">
            <div className="row rowOne">
                <div className="col m12 l6 s12">
                    <img src="img/build.png" alt=""/>
                </div>
                <div className="col m12 l6 s12">
                    <div className="row">
                        <form id="formLogin" action="" onSubmit={onSubmit}>
                            <div className="col s12">
                                <h1>
                                    Iniciar Sesion <br/>
                                    <hr/>
                                </h1>
                            </div>
                            <div className="col s12 input-field">
                                <h6>Usuario</h6>
                                <input 
                                    type="text" 
                                    id="user" 
                                    name="user"
                                    value={form.user}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col s12 input-field">
                                <h6>Contraseña</h6>
                                <input 
                                    type="password" 
                                    id="pass" 
                                    name="password"
                                    value={form.password}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col s12">
                                <div className="boton">
                                    <button 
                                        type='submit' 
                                        className="waves-effect waves-light btn btnFormLoginAdmin"
                                    >
                                        Acceder
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Form
