import React,{useRef} from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import Header from "../components/header";
import Footer from "../components/footer";

import Info from "../components/list/info";

export default class List extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'list',
            list: [],
            error: null,
        }
    }
    
    async componentDidMount(){
        //info
        try{
            let res = await fetch('api/list')
            let data = await res.json()
            this.setState({
                list:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        
    }

    render() {
        return (
            <main>
                <Header/>
                <Info
                    list={this.state.list}
                />
                <Footer/>
            </main>
        );
    }

}

if (document.getElementById('content_list')) {
    ReactDOM.render(<List />, document.getElementById('content_list'))
}
