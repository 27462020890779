import React from 'react'

const Banner = function banner ({banner}){
    return (
        <section className='sectionBanner sectionBannerPrint'>
            <div className='row rowOne'>
                <div className='col s12'>
                    <h1>
                        {banner.name}
                    </h1>
                </div>
            </div>
        </section>
    )
}

export default Banner
