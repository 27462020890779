import React from 'react'

const Form = function form ({form,onChange,onSubmit,onChangeCheck}){
    return (
        <section className='sectionForm'>
            <div className='row rowOne'>
                <form id="formBriefBrand" action="" onSubmit={onSubmit}>
                    <div className='col s12'>
                        <div className='element'>
                        <div className='row'>
                                <div className='col s12'>
                                    <h3>
                                        Nombre o Razón social*
                                    </h3>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí' 
                                        name='name'
                                        value={form.name}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>1.</span> ¿Su empresa a qué se dedica?
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_one'
                                        value={form.q_one}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>2.</span> ¿Quién es tu cliente objetivo o cliente potencial? (Menciona edad, estrato y cualquier otra característica que lo defina)
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_two'
                                        value={form.q_two}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>3.</span> ¿Cuál es tu competencia directa?
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_three'
                                        value={form.q_three}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>4.</span> ¿Qué valores promueve su empresa?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_1'
                                                value={form.values_1}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Respeto</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_2'
                                                value={form.values_2}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Solidaridad</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_3'
                                                value={form.values_3}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Amor</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_4'
                                                value={form.values_4}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Honestidad</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_5'
                                                value={form.values_5}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Empatía</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_6'
                                                value={form.values_6}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Gratitud</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_7'
                                                value={form.values_7}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Responsabilidad</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='values_8'
                                                value={form.values_8}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Voluntad</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='values_other'
                                        value={form.values_other}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>5.</span> ¿Cuál crees que es tu principal diferenciador?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qfive_1'
                                                value={form.qfive_1}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Servicio</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qfive_2'
                                                value={form.qfive_2}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Personal</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qfive_3'
                                                value={form.qfive_3}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Calidad</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qfive_4'
                                                value={form.qfive_4}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Precio</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='qfive_other'
                                        value={form.qfive_other}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>6.</span> ¿Cómo te gustaría que los demás perciban tu marca?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qsix_1'
                                                value={form.qsix_1}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Elegante</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qsix_2'
                                                value={form.qsix_2}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Cercana</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qsix_3'
                                                value={form.qsix_3}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Premium</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qsix_4'
                                                value={form.qsix_4}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Exclusiva</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='qsix_other'
                                        value={form.qsix_other}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='col s12'>
                        <div className='element'>
                        <div className='row'>
                                <div className='col s12'>
                                    <h5>
                                        <span>7.</span> ¿Tu negocio tiene slogan?
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_seven'
                                        value={form.q_seven}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>8.</span> Menciona alguna marca referente con la que te identifiques (Puede ser de tu sector o no)
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_eight'
                                        value={form.q_eight}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>9.</span> Menciona algunos de los colores que deseas sean incluidos en tu marca
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qnine_1'
                                                value={form.qnine_1}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Azul</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qnine_2'
                                                value={form.qnine_2}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Amarillo</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qnine_3'
                                                value={form.qnine_3}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Verde</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qnine_4'
                                                value={form.qnine_4}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Rojo</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qnine_5'
                                                value={form.qnine_5}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Naranja</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='qnine_6'
                                                value={form.qnine_6}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Negro</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='qnine_other'
                                        value={form.qnine_other}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>10.</span> ¿Qué elementos gráficos te gustaría incluir en tu marca? (Iconos representativos del sector)
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_ten'
                                        value={form.q_ten}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>11.</span> ¿Qué elementos gráficos no puede contener la marca? (Cuéntanos si existe algún icono que no deseas que sea incluido)
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_eleven'
                                        value={form.q_eleven}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                <div className='col s12'>
                                    <h5>
                                        <span>12.</span> ¿Por cuál tipografía sientes más afinidad?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p className='p_img'>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q12_one'
                                                value={form.q12_one}
                                                onChange={onChangeCheck}
                                            />
                                            <span>
                                                <img src='img/brand/sanserif.svg'/>
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p className='p_img'>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q12_two'
                                                value={form.q12_two}
                                                onChange={onChangeCheck}
                                            />
                                            <span>
                                                <img src='img/brand/caligrafica.svg'/>
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>13.</span> ¿Por cuál estilo de marca sientes más afinidad?
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p className='p_img_2 p_img_2_initial'>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='lettering'
                                                value={form.lettering}
                                                onChange={onChangeCheck}
                                            />
                                            <span>
                                                <img src='img/brand/style_1.svg'/>
                                                Lettering
                                            </span>
                                        </label>
                                    </p>
                                    <p className='p_img_2'>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='emblem'
                                                value={form.emblem}
                                                onChange={onChangeCheck}
                                            />
                                            <span>
                                                <img className='img_2' src='img/brand/style_2.svg'/>
                                                Emblema
                                            </span>
                                        </label>
                                    </p>
                                    <p className='p_img_2'>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='abstract'
                                                value={form.abstract}
                                                onChange={onChangeCheck}
                                            />
                                            <span>
                                                <img className='img_3' src='img/brand/style_3.svg'/>
                                                Abstracto
                                            </span>
                                        </label>
                                    </p>
                                    <p className='p_img_2 p_img_2_finally'>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='minimalist'
                                                value={form.minimalist}
                                                onChange={onChangeCheck}
                                            />
                                            <span>
                                                <img className='img_4' src='img/brand/style_4.svg'/>
                                                Minimalista
                                            </span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>14.</span> Si tienes comentarios adicionales déjanos saber aquí
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_14'
                                        value={form.q_14}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col s12 button'>
                        <button type='submit' className="waves-effect waves-light btn">
                            Enviar Formulario
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Form