import React from 'react'

const Text = function text ({text}){
    return (
        <section className='sectionText'>
            <div className='row rowOne'>
                <div className='col s12'>
                    <img src='img/lapiz.svg'/>
                    <h1>
                        {text.text_one}
                    </h1>
                    <h2>
                        {text.text_two}
                    </h2>  
                </div>
            </div>
        </section>
    )
}

export default Text
