import React from 'react'

const Info = function info (){
    return (
        <section className="sectionThanks">
            <div className="row rowOne">
                <div className="col s12">
                    <img src="{{asset('img/logo_black.png')}}" alt=""/>
                    <p>
                        <span>¡El brief fue registrado con exito!</span> Estamos muy felices de trabajar junto a tu marca e iniciar este nuevo proyecto.
                        <br/> <br/>
                        Pronto uno de los miembros de nuestro equipo estará en contacto.<br/>
                    </p>
                </div>
            </div>
        </section>

    )
}

export default Info
