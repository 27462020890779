import React,{useRef} from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import Header from "../components/header";
import Footer from "../components/footer";
import Banner from "../components/brand/banner";
import Text from "../components/brand/text";

import Form from "../components/brand/form";

export default class Brand extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'web',
            banner: [],
            text: [],
            formBriefBrand:{
                //brief
                name:'',
                q_one:'',
                q_two:'',
                q_three:'',
                //check
                values_1:'no',
                values_2:'no',
                values_3:'no',
                values_4:'no',
                values_5:'no',
                values_6:'no',
                values_7:'no',
                values_8:'no',
                values_other:'',
                qfive_1:'no',
                qfive_2:'no',
                qfive_3:'no',
                qfive_4:'no',
                qfive_other:'',
                qsix_1:'no',
                qsix_2:'no',
                qsix_3:'no',
                qsix_4:'no',
                qsix_other:'',
                //brief
                q_seven:'',
                q_eight:'',
                //design
                qnine_1:'no',
                qnine_2:'no',
                qnine_3:'no',
                qnine_4:'no',
                qnine_5:'no',
                qnine_6:'no',
                qnine_other:'',
                //brief
                q_ten:'',
                q_eleven:'',
                //design
                q12_one:'no',
                q12_two:'no',
                lettering:'no',
                emblem:'no',
                abstract:'no',
                minimalist:'no',
                //brief
                q_14:'',
            },
            error: null,
        }

        this.formBriefBrandSubmit = this.formBriefBrandSubmit.bind(this)
        this.handleChangeFormBriefBrand = this.handleChangeFormBriefBrand.bind(this)
        this.handleChangeCheck = this.handleChangeCheck.bind(this)
    }
    
    handleChangeFormBriefBrand(e){
        this.setState({
            formBriefBrand:{
                ...this.state.formBriefBrand,
                [e.target.name]:e.target.value
            }
        })
    }

    handleChangeCheck(e){
        let val = '';
        if(e.target.value == 'si'){
            val = 'no'
        }else{
            val = 'si'
        }
        this.setState({
            formBriefBrand:{
                ...this.state.formBriefBrand,
                [e.target.name]:val
            }
        })
    }

    formBriefBrandSubmit = async (e) => {
        e.preventDefault()
        if(
            this.state.formBriefBrand.name == ''
        ){
            swal({
                text: "Debes digitar todos los datos requeridos *",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });
        
        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.formBriefBrand)
            }
            let res = await fetch(`api/briefbrand`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    formBriefBrand:{
                        //brief
                        name:'',
                        q_one:'',
                        q_two:'',
                        q_three:'',
                        //check
                        values_1:'no',
                        values_2:'no',
                        values_3:'no',
                        values_4:'no',
                        values_5:'no',
                        values_6:'no',
                        values_7:'no',
                        values_8:'no',
                        values_other:'',
                        qfive_1:'no',
                        qfive_2:'no',
                        qfive_3:'no',
                        qfive_4:'no',
                        qfive_other:'',
                        qsix_1:'no',
                        qsix_2:'no',
                        qsix_3:'no',
                        qsix_4:'no',
                        qsix_other:'',
                        //brief
                        q_seven:'',
                        q_eight:'',
                        //design
                        qnine_1:'no',
                        qnine_2:'no',
                        qnine_3:'no',
                        qnine_4:'no',
                        qnine_5:'no',
                        qnine_6:'no',
                        qnine_other:'',
                        //brief
                        q_ten:'',
                        q_eleven:'',
                        //design
                        q12_one:'no',
                        q12_two:'no',
                        lettering:'no',
                        emblem:'no',
                        abstract:'no',
                        minimalist:'no',
                        //brief
                        q_14:'',
                    },
                })
                swal.close();
                document.getElementById("formBriefBrand").reset();
                $("#formBriefBrand")[0].reset();
                swal({
                    text: "Gracias, pronto nos comunicaremos con usted",
                    icon: imageURL,
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.href = 'thanks';
                        } else {

                        }
                    });
            }else{
                alert('ocurrio un error');
                /*setTimeout(function (){
                    location.reload();
                },1000)*/
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    async componentDidMount(){
        //banner
        try{
            let res = await fetch('api/banner/2')
            let data = await res.json()
            this.setState({
                banner:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        //text
        try{
            let res = await fetch('api/text/2')
            let data = await res.json()
            this.setState({
                text:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        
    }

    render() {
        return (
            <main>
                <Header/>
                <Banner
                    banner={this.state.banner}
                />
                <Text
                    text={this.state.text}
                />
                <Form
                    form={this.state.formBriefBrand}
                    onChange={this.handleChangeFormBriefBrand}
                    onSubmit={this.formBriefBrandSubmit}
                    onChangeCheck={this.handleChangeCheck}
                />
                <Footer/>
            </main>
        );
    }

}

if (document.getElementById('content_brand')) {
    ReactDOM.render(<Brand />, document.getElementById('content_brand'))
}
