import React,{useRef} from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import Banner from "../components/print/brand/banner";

import Form from "../components/print/brand/form";


export default class PrintBrand extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'printbrand',
            banner: [],
            info_bb: [],
            info_bb_c: [],
            info_bb_d: [],
            error: null,
        }

    }

    async componentDidMount(){
        //banner
        try{
            let res = await fetch('../../api/banner/2')
            let data = await res.json()
            this.setState({
                banner:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        //brief_web
        try{
            let res = await fetch('../../api/brief_brand/'+get_id)
            let data = await res.json()
            this.setState({
                info_bb:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        //brief_web_questions
        try{
            let res = await fetch('../../api/brief_brand_check/'+get_id)
            let data = await res.json()
            this.setState({
                info_bb_c:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        //brief_web_data
        try{
            let res = await fetch('../../api/brief_brand_design/'+get_id)
            let data = await res.json()
            this.setState({
                info_bb_d:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <main>
                <Banner
                    banner={this.state.banner}
                />
                <Form
                    info_bb={this.state.info_bb}
                    info_bb_c={this.state.info_bb_c}
                    info_bb_d={this.state.info_bb_d}
                />
            </main>
        );
    }

}

if (document.getElementById('content_printbrand')) {
    ReactDOM.render(<PrintBrand />, document.getElementById('content_printbrand'))
}
