import React from 'react'

const Info = function info ({list}){
    return (
        <section className='sectionList'>
            <div className='row rowOne'>
                {list.map((l)=>{
                    return (
                        <div className='col s12 m6 l6'>
                             <a 
                                href={l.url+'/list'}
                            >
                                <div className='element'
                                    style={{ 
                                        backgroundImage: `url("${l.img}")` 
                                    }}    
                                >
                                    <div className='back'></div>
                                    <h2>
                                        {l.name}
                                    </h2>
                                </div>
                            </a>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default Info
