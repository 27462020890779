import React from 'react'

const Header = function header (){
    return (
        <nav className='header'>
            <div className="nav-wrapper">
                <a href="#" className="brand-logo center">
                    <img src='/img/logo.svg'/>
                </a>
            </div>
        </nav>
    )
}

export default Header
