var _ = require('lodash');
window.$ = window.jQuery = require('jquery');

require('materialize-css');
require('sweetalert');

require('./views/index');
require('./views/web');
require('./views/brand');
require('./views/thanks');
require('./views/login');
require('./views/list');
require('./views/listweb');
require('./views/seeweb');
require('./views/listbrand');
require('./views/seebrand');

require('./views/printbrand');
require('./views/printweb');