import React from 'react'

const Banner = function banner ({banner}){
    return (
        <section className='sectionBanner'>
            <div className='row rowOne'>
                <div className='col s12'>
                    <div className='element'
                        style={{ 
                            backgroundImage: `url("${'../../'+banner.img}")` 
                        }} 
                    >
                        <div className='back'></div>
                        <h2>
                            {banner.name}
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner
