import React from 'react'

const Text = function text ({form,onChange,onSubmit,onChangeCheck}){
    return (
        <section className='sectionForm'>
            <form id="formBriefWeb" action="" onSubmit={onSubmit}>
                <div className='row rowOne'>
                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                    <div className='col s12'>
                                        <h3>
                                            Nombre o Razón social*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí' 
                                            name='name'
                                            value={form.name}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className='col s12'>
                                        <h3>
                                            Dirección*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='address'
                                            value={form.address}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            Whatsapp principal*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='whatsapp_first'
                                            value={form.whatsapp_first}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            Número celular secundario
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='phone_second'
                                            value={form.phone_second}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className='col s12'>
                                        <h3>
                                            Enlaces de redes sociales*
                                        </h3>
                                        <h4>
                                            (Facebook, Instagram, Twitter, LinkedIn, otras ¿Cuál?)
                                        </h4>
                                        <textarea   
                                            className="materialize-textarea"
                                            placeholder='Escribe texto aquí'
                                            name='link_nets'
                                            value={form.link_nets}
                                            onChange={onChange}
                                        ></textarea>
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            E-mail principal*
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='email_first'
                                            value={form.email_first}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div className='col s12 m6 l6'>
                                        <h3>
                                            E-mail alternativa
                                        </h3>
                                        <input 
                                            type="text" 
                                            placeholder='Escribe texto aquí'
                                            name='email_second'
                                            value={form.email_second}
                                            onChange={onChange}
                                        />
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                <div className='col s12'>
                                    <h5>
                                        <span>1.</span> Sobre tu negocio (Escribe una breve descripción, te sugerimos escribir: Historia de la empresa, cómo surgió, cuáles han sido sus progresos, logros significativos, porque debo escogerlos como  proveedor etc.)
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_one'
                                        value={form.q_one}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>2.</span> Cuéntanos cuáles son los servicios / productos que tu empresa ofrece, si son varios...
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='q_two'
                                        value={form.q_two}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>3.</span> Selecciona los datos que consideras indispensables en el formulario de tu sitio web
                                    </h5>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q3_name'
                                                value={form.q3_name}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Nombre</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q3_phone'
                                                value={form.q3_phone}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Teléfono</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q3_ubication'
                                                value={form.q3_ubication}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Ubicación</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q3_company'
                                                value={form.q3_company}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Nombre de empresa</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q3_mail'
                                                value={form.q3_mail}
                                                onChange={onChangeCheck}
                                            />
                                            <span>E-mail</span>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='q3_gener'
                                                value={form.q3_gener}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Género</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s12'>
                                </div>
                                <div className='col s12 m6 l6'>
                                    <h5>
                                        Otro ¿Cuál?
                                    </h5>
                                    <input 
                                        type="text" 
                                        placeholder='Escribe texto aquí'
                                        name='q3_other'
                                        value={form.q3_other}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col s12'>
                                    <h3 className='h3'>
                                        INFORMACIÓN SOBRE GUSTOS Y PREFERENCIAS
                                    </h3>
                                    <h5>
                                        <span>-</span> Nombre las direcciones de correo requeridas para su negocio (<span className='span_2'>Info@example.com - admon@example.com</span>)
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='emails'
                                        value={form.emails}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>-</span> Páginas de referencia  (Sitios web de otras empresas que pertenezcan o no a su sector para tomar como guia la linea gráfica y experiencia de usuario esperada):
                                    </h5>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='pages_reference'
                                        value={form.pages_reference}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h5>
                                        <span>-</span> ¿Requiere blog?:
                                    </h5>
                                </div>
                                <div className='col s6 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='blog_yes'
                                                value={form.blog_yes}
                                                onChange={onChangeCheck}
                                            />
                                            <span>Si</span>
                                        </label>
                                    </p>
                                </div>
                                <div className='col s6 m6 l6'>
                                    <p>
                                        <label>
                                            <input 
                                                type="checkbox" 
                                                className="filled-in" 
                                                name='blog_no'
                                                value={form.blog_no}
                                                onChange={onChangeCheck}
                                            />
                                            <span>No</span>
                                        </label>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col s12'>
                        <div className='element'>
                            <div className='row'>
                                <div className='col s12'>
                                    <h3>
                                        Por favor compártenos otros datos necesarios (si existen):
                                    </h3>
                                    <h4>
                                        Usuario y claves de dominio
                                    </h4>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='user_password_domain'
                                        value={form.user_password_domain}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h4>
                                        Acceso a las cuentas de correo actuales
                                    </h4>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='access_mail'
                                        value={form.access_mail}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h4>
                                        Usuario y contraseña de Instagram para la integración con el sitio
                                    </h4>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='user_password_instagram'
                                        value={form.user_password_instagram}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h4>
                                        Para analytics: Código de seguimiento
                                    </h4>
                                    <textarea   
                                        className="materialize-textarea"
                                        placeholder='Escribe texto aquí'
                                        name='analitycs_code_follow'
                                        value={form.analitycs_code_follow}
                                        onChange={onChange}
                                    ></textarea>
                                </div>
                                <div className='col s12'>
                                    <h3>
                                        El envío de fotografías, video y otros materiales visuales debe realizarse a los correos: <span>ejecutiva@gutand.com</span> y <span>gerencia@gutand.com</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col s12 button'>
                        <button type='submit' className='waves-effect waves-light btn'>
                            Enviar Formulario
                        </button>
                    </div>
                </div>
            </form>
        </section>
    )
}

export default Text
