import React,{useRef} from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import Header from "../components/header";
import Footer from "../components/footer";
import Banner from "../components/web/banner";
import Text from "../components/web/text";

import Form from "../components/web/form";


export default class Web extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'web',
            banner: [],
            text: [],
            error: null,
            formBriefWeb:{
                name:'',
                address:'',
                whatsapp_first:'',
                phone_second:'',
                link_nets:'',
                email_first:'',
                email_second:'',
                phone_second:'',
                q_one:'',
                q_two:'',
                q3_other:'',
                q3_name:'no',
                q3_phone:'no',
                q3_ubication:'no',
                q3_company:'no',
                q3_mail:'no',
                q3_gener:'no',
                emails:'',
                pages_reference:'',
                blog_yes:'no',
                blog_no:'no',
                user_password_domain:'',
                access_mail:'',
                user_password_instagram:'',
                analitycs_code_follow:'',
                token:'',
            },
        }

        this.formBriefWebSubmit = this.formBriefWebSubmit.bind(this)
        this.handleChangeFormBriefWeb = this.handleChangeFormBriefWeb.bind(this)
        this.handleChangeCheck = this.handleChangeCheck.bind(this)
    }
    
     
    handleChangeFormBriefWeb(e){
        this.setState({
            formBriefWeb:{
                ...this.state.formBriefWeb,
                [e.target.name]:e.target.value
            }
        })
    }

    handleChangeCheck(e){
        let val = '';
        if(e.target.value == 'si'){
            val = 'no'
        }else{
            val = 'si'
        }
        this.setState({
            formBriefWeb:{
                ...this.state.formBriefWeb,
                [e.target.name]:val
            }
        })
    }

    formBriefWebSubmit = async (e) => {
        e.preventDefault()
        if(
            this.state.formBriefWeb.name == '' ||
            this.state.formBriefWeb.address == '' ||
            this.state.formBriefWeb.whatsapp_first == ''||
            this.state.formBriefWeb.link_nets == ''||
            this.state.formBriefWeb.email_first == ''
        ){
            swal({
                text: "Debes digitar todos los datos requeridos *",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });
        
        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.formBriefWeb)
            }
            let res = await fetch(`api/briefweb`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    formBriefWeb:{
                        name:'',
                        address:'',
                        whatsapp_first:'',
                        phone_second:'',
                        link_nets:'',
                        email_first:'',
                        email_second:'',
                        phone_second:'',
                        q_one:'',
                        q_two:'',
                        q3_other:'',
                        q3_name:'no',
                        q3_phone:'no',
                        q3_ubication:'no',
                        q3_company:'no',
                        q3_mail:'no',
                        q3_gener:'no',
                        emails:'',
                        pages_reference:'',
                        blog_yes:'no',
                        blog_no:'no',
                        user_password_domain:'',
                        access_mail:'',
                        user_password_instagram:'',
                        analitycs_code_follow:'',
                        token:'',
                    },
                })
                swal.close();
                document.getElementById("formBriefWeb").reset();
                $("#formBriefWeb")[0].reset();
                swal({
                    text: "Gracias, pronto nos comunicaremos con usted",
                    icon: imageURL,
                    button: "OK!",
                    dangerMode: false,
                    closeOnClickOutside: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            location.href = 'thanks';
                        } else {

                        }
                    });
            }else{
                alert('ocurrio un error');
                /*setTimeout(function (){
                    location.reload();
                },1000)*/
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    async componentDidMount(){
        //banner
        try{
            let res = await fetch('api/banner/1')
            let data = await res.json()
            this.setState({
                banner:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
        //text
        try{
            let res = await fetch('api/text/1')
            let data = await res.json()
            this.setState({
                text:data
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <main>
                <Header/>
                <Banner
                    banner={this.state.banner}
                />
                <Text
                    text={this.state.text}
                />
                <Form
                    form={this.state.formBriefWeb}
                    onChange={this.handleChangeFormBriefWeb}
                    onSubmit={this.formBriefWebSubmit}
                    onChangeCheck={this.handleChangeCheck}
                />
                <Footer/>
            </main>
        );
    }

}

if (document.getElementById('content_web')) {
    ReactDOM.render(<Web />, document.getElementById('content_web'))
}
