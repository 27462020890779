import React,{useRef} from 'react';
import {useState} from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';

import Header from "../components/header";
import Footer from "../components/footer";

import Table from "../components/list/web/table";

//pagination configuration
const DATOS_API = Array.from({length:60},(value,index) => {
    return {
        id: index + 1,
        created_at: '2022-07-08T',
        name: `Nombre${index + 1}`,
        address: 'cra 3b #18h-17',
        whatsapp_first: '3023963859',
        email_first: 'hsmadera18@gmail.com',
    }
})

let ITEMS_FOR_PAGES = 10
let datosFromApi = []
let items = []
let currentPage = 0
let pages = 0

//end pagination configuration

export default class ListWeb extends Component {
    constructor(props){
        super(props)

        this.state = {
            name: 'listWeb',
            list: [],
            items:[],
            error: null,
            filters:{
                id:'',
                date:'',
                name:'',
                address:'',
                whatsapp:'',
                mail:'',
                token:''
            }
        }
        
        this.handleChangeFilter = this.handleChangeFilter.bind(this)
        this.handlePressFilter = this.handlePressFilter.bind(this)

        this.nextHandler = this.nextHandler.bind(this)
        this.prevHandler = this.prevHandler.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
    }

    handleChangeFilter(e){
        this.setState({
            filters:{
                ...this.state.filters,
                [e.target.name]:e.target.value
            }
        })
    }

    handlePressFilter = async (e) =>{
        if (e.key === "Enter") {
            try{
                let config = {
                    method: 'POST',
                    headers:{
                        'Accept':'application/json',
                        'Content-Type':'application/json'
                    },
                    body: JSON.stringify(this.state.filters)
                }
                let res = await fetch(`/api/filtersWeb`,config)
                let data = await res.json()

                datosFromApi = data
                pages = data.length / ITEMS_FOR_PAGES
                currentPage = 0
                items = [...data].splice(currentPage,ITEMS_FOR_PAGES)
                this.setState({
                    list:items
                })
            }catch (error) {
                alert('ocurrio un error');
                this.setState({
                    error
                })
            }
        }
    }

     nextHandler(){
        const totalElementos = datosFromApi.length

        const nextPage = currentPage + 1

        const firstIndex = nextPage * ITEMS_FOR_PAGES

        if(firstIndex >= totalElementos) return

        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)

        this.setState({
            list:items
        })

        currentPage = nextPage
    }

    prevHandler(){
        const prevPage = currentPage - 1
        
        if (prevPage < 0) return

        const firstIndex = prevPage * ITEMS_FOR_PAGES

        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)

        this.setState({
            list:items
        })

        currentPage = prevPage
    }

    changeHandler(id){
        const firstIndex = id * ITEMS_FOR_PAGES
        items = [...datosFromApi].splice(firstIndex,ITEMS_FOR_PAGES)
        this.setState({
            list:items
        })
        currentPage = id
    }

    async componentDidMount(){
        //listWeb
        try{
            let res = await fetch('/api/listWeb')
            let data = await res.json()

            datosFromApi = data
            pages = data.length / ITEMS_FOR_PAGES
            items = [...data].splice(currentPage,ITEMS_FOR_PAGES)
            this.setState({
                list:items
            })
            
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <main>
                <Header/>
                <Table
                    list={this.state.list}
                    filters={this.state.filters}
                    onChange={this.handleChangeFilter}
                    onKeyPress={this.handlePressFilter}

                    prevHandler={this.prevHandler}
                    nextHandler={this.nextHandler}
                    currentPage={currentPage}
                    changeHandler={this.changeHandler}
                    pages={pages}
                />
                <Footer/>
            </main>
        );
    }

}

if (document.getElementById('content_listweb')) {
    ReactDOM.render(<ListWeb />, document.getElementById('content_listweb'))
}
